body {
    font-family: "Raleway", sans-serif;
}

h1 {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
}

h2 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
}

h5 {
    margin: 0;
}

p {
    font-size: 1rem;
    margin: 0;
}

ul {
    margin: 0;
}

/* md down */

@media screen and (max-width: 959px) {
    .paper {
        padding: 1rem;
        margin-top: 2px;
    }
    .MuiContainer-maxWidthLg {
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (max-width: 600px) {
    .MuiContainer-maxWidthLg {
        padding-bottom: 56px;
    }
}

/* md up */

@media screen and (min-width: 960px) {
    .paper {
        padding: 1rem;
        margin: 2rem;
    }
}

/* Small and medium devices */

.recipe-description {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  color: #aaaaaa;
}

.content-separator {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 3.5rem;
  background-color: #277865;
}

.content-separator-text {
  color: #fff;
}

ol {
  padding-left: 1rem;
}

.step {
  padding-bottom: 20px;
}

.img {
  width: 100%;
  height: 500px;
}

/* Small and medium devices */

@media screen and (max-width: 991.98px) {
  .img {
    height: auto;
  }
}

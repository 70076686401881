/* Color pallet #1a5653 #277865 #499a6f #79bc72 #b4dc70 #f9f871*/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
}

a {
    text-decoration: none;
}

p {
    word-wrap: break-word;
}

/* Menu styling */

.navbar {
    margin: auto;
}